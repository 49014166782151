@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

$md-res: 650px;

:root {
  --aj-cream: #f9f9f9;
  --aj-gray: #c9c9c9;
  --aj-purple-800: #434154;
  --aj-black: #000000;

  --aj-dark: var(--aj-black);
  --aj-light: var(--aj-cream);
  --aj-primary: var(--aj-purple-800);

  --aj-font-inter: "Inter", sans-serif;
  --aj-font-lato: "Lato", sans-serif;
  --aj-font-montserrat: "Montserrat", sans-serif;
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: all 300ms ease-in-out;
  font-family: var(--aj-font-montserrat);
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 4px;
    color: var(--aj-dark);
  }
  &::-webkit-scrollbar-thumb {
    border: 5px solid var(--aj-dark);
    border-radius: 4px;
    background: transparent;
  }
}

html {
  font-size: 16px;
}

body {
  background-color: var(--aj-light);
  color: var(--aj-black);
}

.aj {
  &-container {
    width: min(1400px, 100%);
    margin: 0 auto;
    padding-right: 72px;
    position: relative;
  }
  &-body {
    width: min(1440px, 100%);
    padding-right: 48px;
    margin: 0 auto;
    position: relative;
    @media (max-width: $md-res) {
      padding-right: 0;
    }
    & > main {
      display: grid;
      grid-template-columns: repeat(16, 1fr);
      gap: 24px;
      @media (max-width: $md-res) {
        // grid-template-columns: 100%;
        display: block;
      }
    }
  }
  &-navbar {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    mix-blend-mode: difference;
    z-index: 1000;
    display: flex;
    @media (max-width: $md-res) {
      &.active {
        mix-blend-mode: unset;
      }
    }
    & > .container {
      display: flex;
      width: min(1280px, 100%);
      margin: 0 auto;
      & > .mobile-nav {
        position: relative;
        display: flex;
        align-items: center;
        width: 36px;
        aspect-ratio: 1/1;
        margin: 20px 24px 16px auto;
        padding: 4px;
        border-radius: 2px;
        z-index: 3;
        cursor: pointer;
        background-color: transparent;
        border: none;
        & > span {
          height: 2px;
          width: 100%;
          position: relative;
          background-color: var(--aj-light);
          &::after,
          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            bottom: -8px;
            left: 0;
            background-color: var(--aj-light);
          }
          &::after {
            top: -8px;
          }
          &.active {
            transform: rotate(45deg);
            transform-origin: center;
            background-color: var(--aj-light);
            &::after,
            &::before {
              background-color: var(--aj-light);
              transform: rotate(-90deg);
              transform-origin: center;
              top: 0;
              bottom: 0;
            }
          }
        }
        @media (min-width: $md-res) {
          display: none;
        }
      }
      & > nav {
        padding: 20px 24px 16px;
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 24px;
        background-color: var(--aj-dark);
        & > .link {
          font-size: 1.125rem;
          font-weight: 500;
          padding-bottom: 5px;
          color: var(--aj-light);
          text-decoration: none;
          position: relative;
          opacity: 0.5;
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            width: 0%;
            height: 2px;
            background-color: var(--aj-light);
            border-radius: 2px;
            transition: width 300ms ease;
            transform-origin: right;
          }
          &:hover {
            &::after {
              width: 60%;
            }
          }
          &.active {
            opacity: 1;
            &::after {
              width: 60%;
              background-color: var(--aj-light);
            }
          }
        }
        @media (max-width: $md-res) {
          display: none;
          &.active {
            position: absolute;
            width: 100%;
            height: 100dvh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 72px 24px;
            background-color: var(--aj-dark);
          }
        }
      }
      .line {
        //   flex: 1;
      }
    }
  }
  &-socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    position: fixed;
    top: 0;
    right: 0;
    height: 100dvh;
    padding: 56px 20px 16px;
    mix-blend-mode: difference;
    &-links {
      display: flex;
      flex-direction: column;
      gap: 16px;
      & > .link {
        color: var(--aj-light);
        text-decoration: none;
        display: flex;
        padding: 8px;
        border-radius: 50%;
        border: 2px solid transparent;
        & > * {
          transform: scale(1.5);
        }
        &:hover {
          border-color: var(--aj-light);
          & > * {
            transform: scale(1.2);
          }
        }
      }
    }
    & > .line {
      flex: 1;
      width: 2px;
      height: auto;
      background-color: var(--aj-light);
    }
  }
  &-email {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100dvh;
    padding: 56px 20px 0;
    mix-blend-mode: difference;
    & > .link {
      color: var(--aj-light);
      writing-mode: vertical-rl;
      text-orientation: mixed;
      text-decoration: none;
      font-weight: 500;
    }
    & > .line {
      flex: 1;
      width: 2px;
      height: auto;
      margin-right: 4px;
      background-color: var(--aj-light);
    }
  }
  &-image {
    position: sticky;
    top: 0;
    left: 0;
    grid-column: 1 / span 8;
    // &-container
    height: min(900px, 100dvh);
    @media (max-width: $md-res) {
      grid-column: 1 / span 1;
      z-index: -1;
    }
    &.shift {
      top: 72px;
      width: 90%;
      height: auto;
      aspect-ratio: 376/628.66;
      grid-column: 2 / span 5;
      overflow: hidden;
      border-radius: 24px;
      @media (max-width: $md-res) {
        position: unset;
      }
    }
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  &-right-content {
    grid-column: 9 / span 8;
    &.shift {
      grid-column: 7 / span 10;
    }
    @media (max-width: $md-res) {
      grid-column: 1 / span 1 !important;
      background-color: var(--aj-light);
      padding: 0 48px;
    }
    & > .hider {
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      height: 72px;
      background-color: var(--aj-light);
      z-index: 999;
    }
  }
  &-about {
    min-height: min(900px, 100dvh);
    padding: 24px;
    padding-top: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      width: min(535px, 100%);
      & > h1 {
        font-family: var(--aj-font-inter);
        font-weight: 300;
        font-size: 2rem;
      }
      & > .subtitle {
        font-family: var(--aj-font-lato);
        font-weight: 200;
        font-size: 1.2rem;
        margin-bottom: 2rem;
      }
      & > .content {
        font-size: 1rem;
        font-weight: 400;
        text-align: justify;
        margin-bottom: 1rem;
      }
    }
  }
  &-skills {
    padding: 24px;
    padding-top: 72px;
    & > h2 {
      font-family: var(--aj-font-lato);
      font-weight: 200;
      font-size: 2rem;
      margin: 2rem 0;
    }
    & > .grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
      gap: 24px;
      & > .item {
        & > .title {
          display: flex;
          align-items: center;
          gap: 8px;
          & > .icon {
            font-size: 2rem;
          }
          h3 {
            font-family: var(--aj-font-lato);
            font-weight: 200;
            font-size: 1.3rem;
            line-height: 100%;
            width: min(100%, 150px);
          }
        }
        & > p {
          margin-top: 1.2rem;
          font-size: 1rem;
          font-weight: 400;
          text-align: justify;
          width: min(320px, 100%);
        }
      }
    }
    & > .skill-grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(min(100%, 180px), 1fr));
      gap: 24px;
      & > .item {
        position: relative;
        text-align: center;
        padding: 12px;
        border-radius: 12px;
        box-shadow: 1px 1px 7px #646464, -5px -5px 7px #ffffff;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        & > .title {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          & > .icon {
            display: flex;
            font-size: 1.3rem;
          }
          h3 {
            font-family: var(--aj-font-lato);
            font-weight: 300;
            font-size: 1.1rem;
            line-height: 100%;
            margin: 0;
          }
        }
        & > p {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 0.6rem;
          font-weight: 400;
          text-align: justify;
          margin-top: auto;
        }
        &::after,
        &::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 5px;
          background-color: #c9c9c9;
        }
        &::after {
          width: var(--aj-skill-range, 80%);
          background-color: var(--aj-dark);
        }
      }
    }
  }
  &-experience,
  &-education {
    padding: 24px;
    padding-top: 72px;
    & > h2 {
      font-family: var(--aj-font-lato);
      font-weight: 200;
      font-size: 2rem;
      margin: 2.6rem 0;
    }
    & > .exp {
      margin: 24px 0;
      & > .date {
        font-family: var(--aj-font-inter);
        font-size: 0.8rem;
        font-weight: 300;
      }
      & > .title {
        font-family: var(--aj-font-lato);
        font-size: 1.4rem;
        font-weight: 400;
      }
      & > .bottom {
        display: flex;
        align-items: flex-end;
        gap: 12px;
        & > .company {
          font-family: var(--aj-font-inter);
          font-size: 1rem;
          font-weight: 500;
          margin: 0;
        }
        & > address {
          font-size: 0.8rem;
          font-weight: 400;
          margin: 0;
        }
      }
      & > ul {
        margin-top: 12px;
        margin-left: 16px;
        list-style-position: inside;
        & > li {
          font-size: 1rem;
          font-weight: 400;
        }
      }
    }

    & > .project-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
      gap: 24px;
      & > .project {
        & > .cover {
          position: relative;
          overflow: hidden;
          width: 100%;
          aspect-ratio: 4/3;
          & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
          & > .title {
            position: absolute;
            left: 0;
            bottom: 0;
            padding: 8px 32px 8px 12px;
            width: 70%;
            background-color: var(--aj-dark);
            clip-path: polygon(0% 0%, 0% 100%, 100% 100%, calc(100% - 24px) 0%);

            font-size: 1rem;
            font-weight: 400;
            color: var(--aj-light);
          }
        }
        & > h3 {
          margin-top: 1.2rem;
          font-size: 1rem;
          font-weight: 400;
          text-align: justify;
          width: min(320px, 100%);
        }
        & > .details {
          margin-top: 1.2rem;
          font-size: 1rem;
          font-weight: 300;
          text-align: justify;
          width: min(320px, 100%);
        }
        & > .links {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 12px 0px;
          width: 100%;
          margin-top: auto;
          & > .line {
            flex: 1;
            background-color: var(--aj-dark);
            height: 2px;
          }
          & > a {
            font-family: var(--aj-font-lato);
            font-weight: 200;
            font-size: 1.4rem;
            line-height: 100%;
            color: var(--aj-dark);
            text-decoration: none;

            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }
    }
    & > .cta {
      display: flex;
      gap: 24px;
      & > a {
        margin: 24px auto;
        border: 2px solid var(--aj-dark);
        color: var(--aj-dark);
        text-decoration: none;
        padding: 12px 24px;
        &:hover {
          color: var(--aj-light);
          background-color: var(--aj-dark);
        }
      }
    }
  }
  &-interests {
    padding: 24px;
    padding-top: 72px;
    & > h2 {
      font-family: var(--aj-font-lato);
      font-weight: 200;
      font-size: 2rem;
      margin: 2.6rem 0;
    }
    & > .interests {
      & > .interest {
        display: flex;
        align-items: center;
        gap: 24px;
        &:nth-child(even) {
          flex-direction: row-reverse;
        }
        @media (max-width: 850px) {
          flex-direction: column !important;
        }
        & > .image {
          flex: 2;
          // aspect-ratio: 4/5;
          align-self: stretch;
          overflow: hidden;
          border-radius: 12px;
          @media (max-width: 850px) {
            aspect-ratio: 16/9;
          }
          & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        & > .content {
          flex: 3;
          padding: 24px 0;
          & > h3 {
            font-family: var(--aj-font-montserrat);
            font-size: 1.3rem;
            font-weight: 300;
            margin-bottom: 24px;
          }
          & > p {
            font-family: var(--aj-font-inter);
            text-align: justify;
            font-size: 1.1rem;
            font-weight: 300;
            margin: 24px 0;
          }
        }
      }
    }
  }
  &-footer {
    margin-top: -2px;
    padding: 24px 48px;
    text-align: center;
    border: none;
    outline: none;
    font-family: var(--aj-font-inter);
    font-size: 0.9rem;
    & > a {
      font-family: var(--aj-font-montserrat);
      font-weight: 600;
      color: var(--aj-dark);
    }
  }
}
